@@ -1,76 +0,0 @@
<template>
  <v-card class="pa-8">
    <h3>ข้อมูลผู้ใช้งาน</h3>
    <v-row class="text-end">
      <v-col cols="2"><p class="pt-2">username</p></v-col>
      <v-col cols="4">
        <v-text-field
          v-model="username"
          label="username"
          outlined
          dense
        ></v-text-field>
      </v-col>
      <v-spacer></v-spacer>
      <!-- <v-col cols="2"><p class="pt-2">password</p></v-col>
      <v-col cols="4">
        <v-text-field
          v-model="password"
          label="password"
          outlined
          dense
        ></v-text-field>
      </v-col> -->
    </v-row>
    <v-row class="text-end">
      <v-col cols="2"><p class="pt-2">ชื่อจริง</p></v-col>
      <v-col cols="4">
        <v-text-field
          v-model="firstname"
          label="ชื่อจริง"
          outlined
          dense
        ></v-text-field>
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="2"><p class="pt-2">นามสกุล</p></v-col>
      <v-col cols="4">
        <v-text-field
          v-model="lastname"
          label="นามสกุล"
          outlined
          dense
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-btn @click="cancel()">กลับ</v-btn>
      <v-spacer></v-spacer>
      <v-btn @click="submit()">บันทึก</v-btn>
    </v-row>
  </v-card>
</template>
<script>
import { Decode } from "@/services";
export default {
  data() {
    return {
      id: "",
      firstname: "",
      lastname: "",
      username: "",
      password: "",
    };
  },
  created() {
    var userdata = JSON.parse(Decode.decode(localStorage.getItem("userdata")));
    console.log("userdata", userdata);
    this.id = userdata.id;
    this.firstname = userdata.firstname;
    this.lastname = userdata.lastname;
    this.username = userdata.username;
    this.password = userdata.password;
  },
  methods: {
    cancel() {
      this.$router.push("ManageUser");
    },
  },
};
</script>